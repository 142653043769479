import React from 'react';
import useForm from './useForm';
import validate from './validateForm';
import './ContactForm.scss';
require('dotenv').config();

function ContactForm() {

    const { handleChange, handleSubmit, values, errors, isSubmitting } = useForm(submit, validate);

    function submit() {
        // console.log('Email successfully sent!');
    }

    return (
        <form className='contact-form' onSubmit={handleSubmit} noValidate>
            <label htmlFor="name" className="contact-form__label">Name</label>
            <input type="text" name="name" id="name" value={values.name} placeholder="Enter your name" className={errors.name ? "contact-form__info input-error" : "contact-form__info"} onChange={handleChange}/>
            {errors.name && <p className="errors-text">{errors.name}</p>}
            <label htmlFor="email" className="contact-form__label">Email</label>
            <input type="email" name="email" id="email" value={values.email} placeholder="Enter your email address" className={errors.email ? "contact-form__info input-error" : "contact-form__info"} onChange={handleChange}/>
            {errors.email && <p className="errors-text">{errors.email}</p>}
            <label htmlFor="message" className="contact-form__label">Message</label>
            <textarea type="text" name="message" id="message" value={values.message} placeholder="Enter your message" className={errors.message ? "contact-form__message input-error" : "contact-form__message"} onChange={handleChange}></textarea>
            {errors.message && <p className="errors-text">{errors.message}</p>}
            <button type="submit" className="contact-form__submit">Submit</button>
            {!errors.name && !errors.email && !errors.message && isSubmitting && <p className="success-text">Your message has been sent!</p>}
        </form>
    )
}

export default ContactForm;
