import '../../shared/Services.scss';
import Header from '../../components/Header/Header';
import ArtistsAndInfluencersPackages from './ArtistsAndInfluencersPackages';
import CustomPackage from '../../components/CustomPackage/CustomPackage';
import Footer from '../../components/Footer/Footer';
import React from 'react';

function WorkingProfessionals(props) {
    return (
        <div className="demographic">
            <Header/>
            <div className="demographic__body">
                <div className="demographic__body--format">
                    <h1 className="page-title">Artists & Influencers</h1>
                    <ArtistsAndInfluencersPackages/>
                    <CustomPackage/>
                </div>    
            </div>
            <Footer/>
        </div>
    );
}

export default WorkingProfessionals;