export default function validateForm(values){
    let errors = {};

    if (!values.name){
        errors.name= "Name is required";
    } else if (values.name.length < 3){
        errors.name = "Name must be at least 3 characters";
    }

    if(!values.email){
        errors.email= "Email address is required";
    } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(values.email)) {
        errors.email = "Email address is invalid";
    }

    if (!values.message){
        errors.message= "Message is required";
    } else if (values.message.length < 10){
        errors.message = "Message must be at least 10 characters";
    }

    return errors;
}