import { useState, useEffect } from "react";
import emailjs from 'emailjs-com';

const useForm = (callback, validate) => {
    const [values, setValues] = useState({name: "", email: "", message: ""});
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);


    const handleChange = (e) => {
        const { name, value } = e.target;
       
        setValues(
            {
            ...values,  
            [name]: value
            }
        )
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors(validate(values));
        setIsSubmitting(true);
        if(Object.keys(validate(values)).length === 0){
            emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, e.target, process.env.REACT_APP_USER_ID)
            .then((result) => {
                // console.log(result.text);
            }, (error) => {
                // console.log(error.text);
            });
        }        
    };

    useEffect(() => {
        if(Object.keys(errors).length === 0 && isSubmitting) {
            // callback();
            setValues({name: "", email: "", message: ""});
        }
    }, [errors, callback, isSubmitting])

    return {
        handleChange,
        handleSubmit,
        values,
        errors,
        isSubmitting
    };
};

export default useForm;