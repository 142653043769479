import '../../shared/ServicesAlt.scss';
import React from 'react';

function PackageItems({packageItems}) {

    return(
        <>
        {packageItems.map((item, index) => {
            return (
                <p className="package-body__description">
                    <span className="package-body__description--bold">{item.packageItemTitle}</span>{item.packageItem}
                </p>
            )
        })}
        </>
    )
    
}

export default PackageItems;