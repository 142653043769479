import '../../shared/ServicesAlt.scss';
import Header from '../../components/Header/Header';
import WorkingProfessionalPackages from './WorkingProfessionalPackages';
import CustomPackageAlt from '../../components/CustomPackage/CustomPackageAlt';
import Footer from '../../components/Footer/Footer';
import React from 'react';

function WorkingProfessionals(props) {
    return (
        <div className="demographic">
            <Header/>
            <div className="demographic__body">
                <div className="demographic__body--format">
                    <h1 className="page-title">Working Professionals</h1>
                    <WorkingProfessionalPackages/>
                    <CustomPackageAlt/>
                </div>    
            </div>
            <Footer/>
        </div>
    );
}

export default WorkingProfessionals;