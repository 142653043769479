export const MenuItems = [
    {
        title: 'About',
        url: '/',
        cName: 'nav-link'
    },
    {
        title: 'Services',
        url: '#services',
        cName: 'nav-link'
    },
    {
        title: 'Artists & Influencers',
        url: "/services/artists-and-influencers",
        cName: 'nav-link nav-link--service'
    },
    {
        title: 'Working Professionals',
        url: '/services/working-professionals',
        cName: 'nav-link nav-link--service'
    },
    {
        title: 'Retirees',
        url: '/services/retirees',
        cName: 'nav-link nav-link--service'
    },
    {
        title: 'Personal Shopping',
        url: '/services/personal-shopping',
        cName: 'nav-link nav-link--service'
    },
    {
        title: 'Contact',
        url: '#contact',
        cName: 'nav-link'
    }
]