import '../../shared/ServicesAlt.scss';
import Header from '../../components/Header/Header';
// import PersonalShoppingPackages from './PersonalShoppingPackages';
// import CustomPackageAlt from '../../components/CustomPackage/CustomPackageAlt';
import CustomPackagePersonalShopping from '../../components/CustomPackage/CustomPackagePersonalShopping'
import Footer from '../../components/Footer/Footer';
import React from 'react';

function PersonalShopping(props) {
    return (
        <div className="demographic">
            <Header/>
            <div className="demographic__body demographic__body--personal-shopping">
                <div className="demographic__body--format">
                    <h1 className="page-title">Personal Shopping</h1>
                    {/* <PersonalShoppingPackages/> */}
                    <CustomPackagePersonalShopping/>
                </div>    
            </div>
            <Footer/>
        </div>
    );
}

export default PersonalShopping;