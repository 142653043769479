export const Packages = [
    {
        package: 'BASIC PACKAGE',
        packageLine1: 'BASIC',
        packageLine2: 'PACKAGE',
        rate: '$60.00/hour',
        rateTerms: 'No Contract - Pay Per Hour',
        packageDescription: 'Being an artist and/or influencer is a time-consuming career. Let me alleviate some of your stress by taking care of your wardrobe. Do you have a show coming up? Maybe a music video or a photoshoot? Whatever you have going on, I will be your personal stylist, making sure you stand out from the rest. What’s included:',
        packageItems: [
            {
            packageItemTitle: 'A one-on-one free consultation',
            packageItem:' – This allows me to meet you and get to know you a little better. This time will be used to get to know your style, your personality, the event we are styling for, understanding your budget, and for us to create a plan going forward.'
            },
            {
            packageItemTitle: 'A mood board',
            packageItem: ' – I will work with you to create a mood board of what the idea is for the outfit(s) along with ideas for makeup and hair. Minimum charge: $40.00.'    
            },
            {
            packageItemTitle: 'A day (or a few days) of shopping',
            packageItem: ' – I will take you shopping, or I can do the shopping for you, whatever your preference. We will be shopping at stores that match your style, personality, event, and budget. I will be with you to offer my expertise, thoughts, opinions, and show you how to style the look(s). This can be as many hours as you prefer. $60.00 per hour.'
            },
            {
            packageItemTitle: 'Optional:',
            packageItem: ' I can be with you at your event to help style you and make sure everything with your look is absolutely perfect. $60.00 per hour.'
            },
            {
            packageItemTitle: 'Optional: A photoshoot with me',
            packageItem: ' – We can arrange a photoshoot for headshots, social media content, album art, whatever you need, etc. We will work together on what the idea is, location, lighting, etc. You will then receive 10 digital jpeg images for you to use for a flat fee of $200.00.'  
            }
        ]
    },
    {
        package: 'PROFESSIONAL PACKAGE',
        packageLine1: 'PROFESSIONAL',
        packageLine2: 'PACKAGE',
        rate: '$1995.00',
        rateTerms: '3 Month Contract',
        packageDescription: 'You are a busy person, working hard to build your career; let me take care of your style for you. I will be part of your team, making sure you are always ready for what’s happening next. You will never have to worry about your wardrobe; let me do all the heavy lifting. I will be there to style you for every event you need, making sure you are looking your best and maintaining your own personal style. This package includes:',
        packageItems: [
            {
            packageItemTitle: 'A one-on-one free consultation',
            packageItem:' – This allows me to meet you and get to know you a little better. This time will be used to get to know your style, your personality, the events we are styling for, understanding your budget, and for us to create a plan going forward.'
            },
            {
            packageItemTitle: 'A mood board',
            packageItem: ' – I will work with you to create a mood board for your work attire; styling outfits to meet your profession’s needs along with ideas for makeup and hair to complete your look.'    
            },
            {
            packageItemTitle: '40 hours total of my in-person service',
            packageItem: ' – These are a bank of hours; you can use them how you see fit. These hours can be allocated to shopping with or for you, as well as if you would like me to be with you at or before any of the events to be sure that your outfits are absolutely perfect. A great value for an invested amount of time in getting you set up and ready to sustain a renewed you that will look and feel great while helping you stand out and get noticed. If you need any additional hours, I’m here for you for a cost of $50.00 extra per hour.'
            },
            {
            packageItemTitle: 'My expertise whenever you need it',
            packageItem: ' - These hours are allocated to shopping with or for you, and for the full wardrobe consultation. A great value for an invested amount of time in getting you set up and ready to sustain a renewed you that will look and feel great and help you stand out and get noticed. If you need any additional hours, I’m here for you for a cost of $50.00 extra per hour.'
            },
            {
            packageItemTitle: 'My expertise whenever you need it',
            packageItem: ' – For the full three months you can call or text me for any additional styling questions and help you need.'
            },
            {
            packageItemTitle: 'Optional: A photoshoot with me',
            packageItem: ' – We can arrange a photoshoot for headshots, social media content, album art, whatever you need, etc. We will work together on what the idea is, location, lighting, etc. You will then receive 10 digital jpeg images for you to use for a flat fee of $200.00.'
            }
        ] 
    },
    {
        package: 'DELUXE PACKAGE',
        packageLine1: 'DELUXE',
        packageLine2: 'PACKAGE',
        rate: '$7299.00',
        rateTerms: '1 Year Contract',
        packageDescription: 'Do you need a stylist to be a part of your team for an event or your personal life? I’m the personal stylist you need, with my top priority to make your style and image shine and catch eyes. I will pay close attention to your style to help you maintain a cohesive style and brand that fits you. There is enough in life to worry about, let me handle your wardrobe. This package includes:',
        packageItems: [
            {
            packageItemTitle: 'A one-on-one consultation',
            packageItem:' – This allows me to meet you and get to know you a little better. This time will be used to get to know your style, your personality, the events we are styling for, understanding your budget, and for us to create a plan going forward.'
            },
            {
            packageItemTitle: 'A full wardrobe consultation',
            packageItem: ' – I will go through your closet with you. We will discuss your style, what you plan to keep, donate or throw away, how to style the current pieces you have, and I will re-organize your closet so that the layout is the best fit for you.'    
            },
            {
            packageItemTitle: 'A mood board',
            packageItem: ' – I will work with you to create a mood board for each event for what the idea is for the outfits, along with ideas for makeup and hair.'
            },
            {
            packageItemTitle: '20 hours a month (or 240 hours a year) of my in-person service',
            packageItem: ' – These are a bank of hours to be used how you see fit. These hours are allocated to shopping with or for you, as well as if you would like me to be with you at or before any of the events to be sure that your outfits are absolutely perfect. If you want the best bang for your buck, this is the package for you, If you need any additional hours, it will be $40.00 extra per hour.'
            },
            {
            packageItemTitle: 'My expertise whenever you need it',
            packageItem: ' – For the full year you can call or text me for any additional styling questions and help that you need.'
            },
            {
            packageItemTitle: 'Optional: A photoshoot with me',
            packageItem: ' - We can arrange a photoshoot for headshots, social media content, album art, whatever you need, etc. We will work together on what the idea is, location, lighting, etc. You will then receive 10 digital jpeg images for you to use for a flat fee of $200.00.'
            }
        ]   
    }    
]