import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import HeaderLogo from '../../assets/logos/final-logos-white-03.png';
import { MenuItems } from './MenuItems';


import './Header.scss';

class Header extends Component {
    state = {
        clicked: false
    }
    handleClick = () => {
        this.setState({clicked: !this.state.clicked})
    }
    handleLinkClick = () => {
        this.setState({clicked: false})
    }

    render(){

        const handleNavClick = () => {
            window.scrollTo(0, 0);
        }
    
        return (
            <header className="header">
                <NavHashLink to = "/" className="logo-link" onClick={handleNavClick}>
                    <img src={HeaderLogo} alt="Style By Mel Logo" className="logo-link__image"/>
                </NavHashLink>
                <nav className="menu-icon-container" onClick={this.handleClick}>
                    <div className={this.state.clicked ? "menu-icon menu-icon--animate" : 'menu-icon'}>
                    </div>
                </nav>
                <ul className={this.state.clicked ? 'nav-menu nav-menu--active' : 'nav-menu'}>
                    {MenuItems.map((item, index) => {
                        return(
                            <li key={index} className="nav-links">
                                <NavHashLink to={item.url} className={item.cName} onClick={this.handleLinkClick}>
                                    {item.title}
                                </NavHashLink>
                            </li>
                        )
                    })}                    
                </ul>
                <nav className="nav-bar-links">
                    <NavHashLink to="/" className="nav-bar-links__link">About</NavHashLink>
                    <NavHashLink to="#services" className="nav-bar-links__link">
                        Services
                        <div className="dropdown-menu">
                            <Link to="/services/artists-and-influencers" className="dropdown-menu__link">Artists & Influencers</Link>
                            <Link to="/services/working-professionals" className="dropdown-menu__link">Working Professionals</Link>
                            <Link to="/services/retirees" className="dropdown-menu__link">Retirees</Link>
                            <Link to="/services/personal-shopping" className="dropdown-menu__link">Personal Shopping</Link>
                        </div>
                    </NavHashLink>
                    <NavHashLink to="#contact" className="nav-bar-links__link">Contact</NavHashLink>
                </nav>                        
            </header>
        );
    }
}

export default Header;