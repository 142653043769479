export const Packages = [
    {
        package: 'BASIC PACKAGE',
        packageLine1: 'BASIC',
        packageLine2: 'PACKAGE',
        rate: '$495.00',
        rateTerms: 'One-Time Fee',
        packageDescription: 'You’re retired; that doesn’t mean your style has to be as well. Do you want an update to your style? Maybe you just want a few more outfits to get you feeling yourself again. Whatever your feeling, let me take care of you and help you feel like yourself again. I will make sure you get outfits that match your style and are timeless. This package includes:',
        packageItems: [
            {
            packageItemTitle: 'A one-on-one free consultation',
            packageItem:' – This allows me to meet you and get to know you a little better. This time will be used to get to know your style, your personality, the outfits we want/need to focus on, understanding your budget, and for us to create a plan going forward.'
            },
            {
            packageItemTitle: '8 hours of shopping',
            packageItem: ' – I will take you shopping, or I can do the shopping for you, whatever your preference. We will be shopping at stores that match your style, personality, and budget. I will be with you to offer my expertise, thoughts, opinions, and show you how to style the looks. This can be as many hours as you want or need up to 8 hours.'    
            },
            {
            packageItemTitle: '2-hour final consultation with the new looks',
            packageItem: ' – I will go through the new looks with you and show you how to style them with things you already have and what you have purchased.'
            },
            {
            packageItemTitle: 'Note',
            packageItem: ' - If you need any additional hours, it will be $60.00 extra per hour.'
            },
            {
            packageItemTitle: 'My expertise whenever you need it',
            packageItem: ' – For a full 3 months you can call, email or text me for any additional styling questions and help that you need.'  
            }
        ]
    },
    {
        package: 'DELUXE PACKAGE',
        packageLine1: 'DELUXE',
        packageLine2: 'PACKAGE',
        rate: '$899.00',
        rateTerms: 'One-Time Fee',
        packageDescription: 'Do you feel like you need a complete wardrobe revamp? Want some new styles, and advice on how to style what you’ve got? I’m your girl. I will help you update your style as well as show you how to style what you already have. Just because you’re retired, doesn’t mean you can’t be stylish. I will take care of all of your styling needs. This package includes:',
        packageItems: [
            {
            packageItemTitle: 'A one-on-one free consultation',
            packageItem:' – This allows me to meet you and get to know you a little better. This time will be used to get to know your style, your personality, the outfits we want/need to focus on, understanding your budget, and for us to create a plan going forward.'
            },
            {
            packageItemTitle: 'A 5-hour full wardrobe consultation',
            packageItem: ' – I will go through your closet with you, we will discuss your style, what you plan to keep, donate or throw away, how to style the current pieces you have, and I will re- organize your closet so that the layout is the best fit for you.'    
            },
            {
            packageItemTitle: '12 hours of shopping',
            packageItem: ' – I will take you shopping, or I can do the shopping for you, whatever your preference. We will be shopping at stores that match your style, personality, and budget. I will be with you to offer my expertise, thoughts, opinions, and show you how to style the looks. This can be as many hours as you want or need up to 12 hours.'
            },
            {
            packageItemTitle: '3-hour final consultation with the new looks',
            packageItem: ' – I will go through the new looks with you and show you how to style them with things you already have and what you have purchased.'
            },
            {
            packageItemTitle: 'My expertise whenever you need it',
            packageItem: ' – for a full 3 months you can call, email or text me for any additional styling questions and help you need.'
            },
            {
            packageItemTitle: 'Note',
            packageItem: ' - A great value for an invested amount of time in getting you set up and ready to sustain a renewed you that will look and felt great and help you stand out and get noticed. If you need any additional hours, I’m here for you for a cost of $60.00 extra per hour.'
            }
        ] 
    }        
]