export const Packages = [
    {
        package: 'BASIC PACKAGE',
        packageLine1: 'BASIC',
        packageLine2: 'PACKAGE',
        rate: '$60.00/hour',
        rateTerms: 'No Contract - Pay Per Hour',
        packageDescription: 'Do you feel like your style has been a little neglected? Want a quick style revamp to get you feeling more like yourself? Do you want people to notice you and take you seriously? Let me update your wardrobe with a few new styles to get you looking and feeling your best again. I will sit down with you to understand your style goals, what you love to wear and what makes you, you! You will be the talk of your friends, family and peers when we’re done. This package includes:',
        packageItems: [
            {
            packageItemTitle: 'A one-on-one free consultation',
            packageItem:' – This allows me to meet you and get to know you a little better. This time will be used to get to know your style, your personality, the outfits we want/ need to focus on, understanding your budget, and for us to create a plan going forward.'
            },
            {
            packageItemTitle: 'A mood board',
            packageItem: ' – I will work with you to create a mood board of what the idea is for outfits along with ideas for makeup and hair. Minimum charge: $40.00.'    
            },
            {
            packageItemTitle: 'A day (or a few days) of shopping',
            packageItem: ' – I will take you shopping, or I can do the shopping for you, whatever your preference. We will be shopping at stores that match your style, personality, job, and budget. I will be with you to offer my expertise, thoughts, opinions, and show you how to style the looks. This can be as many hours as you want or need. $60.00 per hour.'
            },
            {
            packageItemTitle: 'Optional: A photoshoot with me',
            packageItem: ' – We can arrange a photoshoot for headshots, outfit looks, whatever you need, etc. We will work together on what the idea is, location, lighting, etc. You will then receive 10 digital jpeg images for you to use for a flat fee of $200.00.'
            }
        ]
    },
    {
        package: 'DELUXE PACKAGE',
        packageLine1: 'DELUXE',
        packageLine2: 'PACKAGE',
        rate: '$899.00',
        rateTerms: 'One-Time Fee',
        packageDescription: 'Starting your career? Got a promotion? On a new path in life? Want to impress your partner? Whatever it is, start off strong with an update to your wardrobe. I will make sure you feel like a new and improved version of yourself, making sure not only to give you a few extra looks, but to show you what you already have and how you can use your clothes to your advantage. Let me take care of you. This package includes:',
        packageItems: [
            {
            packageItemTitle: 'A one-on-one free consultation',
            packageItem:' – This allows me to meet you and get to know you a little better. This time will be used to get to know your style, your personality, the outfits we want/need to focus on, understanding your budget, and for us to create a plan going forward.'
            },
            {
            packageItemTitle: 'A full wardrobe consultation',
            packageItem: ' – I will go through your closet with you, we will discuss your style, what you plan to keep, donate or throw away, how to style the current pieces you have, and I will re-organize your closet so that the layout is the best fit for you.'    
            },
            {
            packageItemTitle: 'A mood board',
            packageItem: ' – I will work with you to create a mood board of what the idea is for outfits along with ideas for makeup and hair.'
            },
            {
            packageItemTitle: '20 hours total of my in-person service',
            packageItem: ' - These hours are allocated to shopping with or for you, and for the full wardrobe consultation. A great value for an invested amount of time in getting you set up and ready to sustain a renewed you that will look and feel great and help you stand out and get noticed. If you need any additional hours, I’m here for you for a cost of $60.00 extra per hour.'
            },
            {
            packageItemTitle: 'My expertise whenever you need it',
            packageItem: ' – For a full month you can call or text me for any additional styling questions and help that you need.'
            },
            {
            packageItemTitle: 'Optional:',
            packageItem: ' A photoshoot with me – We can arrange a photoshoot for headshots, outfit looks, whatever you need, etc. We will work together on what the idea is, location, lighting, etc. You will then receive 10 digital jpeg images for you to use for a flat fee of $200.00.'
            }
        ] 
    },
    {
        package: 'MATERNITY PACKAGE',
        packageLine1: 'MATERNITY',
        packageLine2: 'PACKAGE',
        rate: '$399.00',
        rateTerms: 'One-Time Fee',
        packageDescription: 'Congratulations on your bundle of joy! Whether it’s your first or your fifth, your body is going through incredible changes, which means your wardrobe will too. Don’t fall into the fallacy that you should be wearing sweatpants and t-shirts throughout your pregnancy. You are beautiful and radiant, and the world wants to see you and your growing bump in all its glory! I will show you how to dress stylish all while still remaining comfy. I will be there for you throughout your entire pregnancy to make sure you are always looking and feeling your best. This package includes:',
        packageItems: [
            {
            packageItemTitle: 'A one-on-one free consultation',
            packageItem:' – This allows me to meet you and get to know you a little better. This time will be used to get to know your style, your personality, the outfits we want/need to focus on, understanding your budget, and for us to create a plan going forward.'
            },
            {
            packageItemTitle: '8 hours of shopping',
            packageItem: ' – I will take you shopping, or I can do the shopping for you, whatever your preference. We will be shopping at stores that match your style, personality, job, and budget. I will be with you to offer my expertise, thoughts, opinions, and show you how to style the looks. This can be as many hours as you want or need. If you need any additional hours, it will be $60.00 extra per hour.'    
            },
            {
            packageItemTitle: 'My expertise whenever you need it',
            packageItem: ' – For your full pregnancy you can call or text me for any additional styling questions and help you need.'
            },
            {
            packageItemTitle: 'Optional: A photoshoot with me',
            packageItem: ' – We can arrange a maternity photoshoot. We will work together on what the idea is, location, lighting, etc. You will then receive 10 digital jpeg images for a flat fee of $200.00.'
            }
        ]   
    }    
]