import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header/Header';
import HeroText from '../../assets/logos/final-logos-colour-02.png';
import MelProfilePic from '../../assets/photos/melissa/BP04 copy.jpg'
import ContactMePic from '../../assets/photos/melissa/BP07.jpg'
import ContactForm from '../../components/ContactForm/ContactForm';
import Footer from '../../components/Footer/Footer';

import './HomePage.scss';

function HomePage(props) {

    const handleNavClick = () => {
        window.scrollTo(0, 0);
    }

    return (
        <div className="home-page">
            <Header/>
            <div className="hero-container">
                <div className="hero-image-left"></div>
                <div className="hero-image">
                    <img src={HeroText} alt="Style By Mel" className="hero-image__text"/>
                </div>
                <div className="hero-image-right"></div>
            </div>
            <section className="about-me" id="about">
                <div className="profile-container">
                    <div className="profile">
                        <h2 className="profile__title">
                            About Me
                        </h2>
                        <div className="profile-frame">
                            <img src={MelProfilePic} alt="Melissa Profile" className="profile-frame__image"/>                        
                        </div>
                    </div>
                    <div className="profile-bio">
                        <p className="profile-bio__text">
                            Hi there, I’m Melissa Mellegers, here to be your personal stylist. Styling has always been my passion; so much so, that I studied it. My goal in life has always been to help people feel their best, by looking their best. Does your life feel like it needs an update or just a refresh, even in a small way? Let my styling get you feeling on top of your game. I will put your needs first to make you feel like you again. Some people want to make beautiful things, but I want to make people feel beautiful. 
                        </p>
                    </div>
                </div>
            </section> 
            <section className="services"  id="services">
                <h2 className="services__title">
                    Services
                </h2>
                <div className="services-cards">
                    <div className="services-cards-container">
                        <div className="services-cards-top">
                            <Link to="/services/artists-and-influencers" className="services-card" onClick={handleNavClick}>
                                <div className="card-image-1"></div>
                                <div className="card-text">
                                    <p className="card-text__style">ARTISTS & INFLUENCERS</p>
                                </div>    
                            </Link>
                            <Link to="/services/working-professionals" className="services-card" onClick={handleNavClick}>
                                <div className="card-image-2"></div>
                                <div className="card-text">
                                    <p className="card-text__style">WORKING PROFESSIONALS</p>
                                </div>    
                            </Link>
                        </div>
                        <div className="services-cards-bottom">
                            <Link to="/services/retirees" className="services-card" onClick={handleNavClick}>
                                <div className="card-image-3"></div>
                                <div className="card-text">
                                    <p className="card-text__style">RETIREES</p>
                                </div>    
                            </Link>
                            <Link to="/services/personal-shopping" className="services-card" onClick={handleNavClick}>
                                <div className="card-image-4"></div>
                                <div className="card-text">
                                    <p className="card-text__style">PERSONAL SHOPPING</p>
                                </div>    
                            </Link>
                        </div>
                    </div>
                    <div className="services-cards__background"></div>
                </div>
            </section>
            <section className="contact-me" id="contact">
                <div className="contact-container">
                    <div className="contact-profile">
                        <h2 className="contact-profile__title">
                            Contact Me
                        </h2>
                        <div className="contact-profile-frame">
                            <img src={ContactMePic} alt="Melissa Contact" className="contact-profile-frame__image"/>                        
                        </div>
                    </div>
                    <div className="contact-client">
                        <p className="contact-client__text">
                            Have a question, comment or inquire? Feel free to reach out to me anytime!
                        </p>
                        <ContactForm/>
                    </div>
                </div>
            </section>
            <Footer/>            
        </div>
    );
}

export default HomePage;