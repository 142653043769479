import './App.scss';

import { BrowserRouter, Switch, Route } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import ArtistsAndInfluencers from './pages/ArtistsAndInfluencers/ArtistsAndInfluencers';
import WorkingProfessionals from './pages/WorkingProfessionals/WorkingProfessionals';
import PersonalShopping from './pages/PersonalShopping/PersonalShopping';
import Retirees from './pages/Retirees/Retirees';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path = "/" exact component = {HomePage} />
          <Route path = "/services/artists-and-influencers" exact component = {ArtistsAndInfluencers} />
          <Route path = "/services/working-professionals" exact component = {WorkingProfessionals} />
          <Route path = "/services/retirees" exact component = {Retirees} />
          <Route path = "/services/personal-shopping" exact component = {PersonalShopping} />
        </Switch>
      </BrowserRouter>
      
    </div>
  );
}

export default App;
