import React from 'react';
import { Packages } from './Packages';
import PackageItems from './PackageItems';

import '../../shared/Services.scss';

function RetireesPackages(props) {

    return (
        <>
            {Packages.map((item, index) => {
                return(
                    <div className="package">
                        <h2 className="package__title">{item.package}</h2>
                        <div className="package-title">
                            <h2 className="package-title__text">{item.packageLine1}</h2>
                            <h2 className="package-title__text">{item.packageLine2}</h2>
                        </div>
                        <div className="package-body">
                            <h3 className="package-body__rate">{item.rate}</h3>
                            <h3 className="package-body__rate">{item.rateTerms}</h3>
                            <p className="package-body__description">
                            {item.packageDescription}
                            </p>
                            <PackageItems packageItems = { item.packageItems }/>
                        </div>
                    </div>
                )
            })}

        </>
    );
}

export default RetireesPackages;