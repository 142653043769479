import '../../shared/Services.scss';
import ContactForm from '../ContactForm/ContactForm';

import React from 'react';

function CustomPackage(props) {
    return (
        <div className="package">
            <h2 className="package__title">CUSTOM PACKAGE</h2>
            <div className="package-title">
                <h2 className="package-title__text">CUSTOM</h2>
                <h2 className="package-title__text">PACKAGE</h2>
            </div>
            <div className="package__contact-form-container" id="contact">
                <p className="package__custom">
                None of these packages exactly what you want? No problem let’s work out a package that is perfect for you. Just tell me what you are looking for, what you want done or what services you’re interested in, and I will build you your own custom package.
                </p>                
                <ContactForm/>
            </div>
        </div>
    );
}

export default CustomPackage;