import './Footer.scss'
import React from 'react';
import Facebook from '../../assets/logos/iconmonstr-facebook-2-240-white.png';
import Instagram from '../../assets/logos/iconmonstr-instagram-12-240-white.png';
import YouTube from '../../assets/logos/iconmonstr-youtube-7-240-white.png';

function Footer(props) {
    return (
        <div className="footer">
            <div className="social-links">
                <a href="https://www.instagram.com/style.by.mel/" className="social">
                    <img src={Instagram} alt="link to instagram page" className="social__icon"/>
                </a>
                <a href="https://www.facebook.com/Style-By-Mel-100495028582248" className="social">
                    <img src={Facebook} alt="link to facebook page" className="social__icon"/>
                </a>
                <a href="https://www.youtube.com/channel/UCnTw--x-7dL8gDXq9VJju5g" className="social">
                    <img src={YouTube} alt="link to youtube page" className="social__icon"/>
                </a>
            </div>
            <div className="footer-text">
                <p className="footer-text--format">Photography By Mellissa Mellegers & Ron Mellegers | </p>
                <p className="footer-text--format">Website By <a href="https://www.linkedin.com/in/stevenloewen/" className="steve-link">Steven Loewen</a></p>
                
            </div>            
        </div>
    );
}

export default Footer;